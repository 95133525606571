<!-- 审核日记 -->
<template>
    <div class="AuditJournal">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">审核日志</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <!-- <el-form ref="form" :model="form" inline class="form" id="form" label-width="90px">
            <el-form-item label="安排单号">
                <el-input :value="form.planNum" placeholder="" ></el-input>
            </el-form-item>
            <el-form-item label="安排日期">
                <el-date-picker
                      v-model="form.planDate"
                      type="date"
                      placeholder="选择日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="审核人">
                <el-input :value="form.examine" placeholder="请输入姓名" ></el-input>
            </el-form-item>
            <el-form-item label="">
                <div class="" style="margin-right: 10px;">
                    <el-button size="small" style="background: #18BC9C;color: #FFFFFF;">
                        查询
                    </el-button>
                    <el-button size="small" style="background: #FAFAFA;color: #666666;">
                        重置
                    </el-button>
                </div>
            </el-form-item>
        </el-form> -->
        <!-- 按钮 -->
        <div class="" style="margin-left: 21px;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <!-- <button class="derive">导出到Excel</button> -->
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:98%;flex: 1;" height="650" id="out-table">
            <el-table-column prop="number" sortable label="安排单号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="arrange_date" sortable label="安排日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="examine_time" sortable label="审核时间" align="center" width="200">
            </el-table-column>
            <el-table-column prop="examine_aid" sortable label="审核人" align="center" width="100">
            </el-table-column>
            <el-table-column prop="examine_start" sortable label="审核前状态" align="center" width="200">
            </el-table-column>
            <el-table-column prop="examine_end" sortable label="审核后状态" align="center" width="200">
            </el-table-column>
            <el-table-column prop="examine_remark" sortable label="审核意见" align="center" >
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form: {
                    planNum: "", //安排单号
                    planDate: "", //安排日期
                    examine: "" //审核人
                },
                currentPage: 1,
                currentLimit: 20,//条数
                total: 0,
                tableData:[]
            }
        },
        created() {
            this.getList()
        },
        methods:{
            // 列表
            getList(){
                this.$request({
                    url:'/api/examarrange/verify_list',
                    method:'POST',
                    data:{
                        id:this.$route.query.id
                    }
                }).then(res=>{
                    console.log(res)
                    if(res.code){
                        this.tableData=res.data.list
                        this.total=res.data.total
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val//条数
                // this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                // this.getList()
                // console.log(`当前页: ${val}`);
            },
            goBack() { //返回
                this.$router.go(-1);
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
        }
    }
</script>

<style scoped="scoped">
    .AuditJournal{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单 */
    .el-form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .form::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .form::v-deep .el-input__inner {
        background: #fff !important;
        width: 276px;
        height: 37px !important;
    }
    /* 刷新按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    /* 导出按钮 */
    .derive {
        display: inline-block;
        width: 113px;
        height: 30px;
        padding-left: 10px;
        vertical-align: middle;
        background: url(../../assets/export.png) 6px 6px no-repeat #FF7300;
        color: #FFFFFF;
        border-radius: 3px;
        border: none;
        margin-left: 10px;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
